<template>
	<div class="modal-card" style="width: auto">
		<header class="modal-card-head">
			<p class="modal-card-title">צפייה בהיסטוריית קופה</p>
		</header>

		<section class="modal-card-body">
			<b-field>
				<b-select v-model="selectedCashier" required icon="cash-register" placeholder="בחרו קופה לצפייה..." ref="logsList">
					<option v-for="log in logsList" :key="log.id" :value="log.id">{{ log.cashierTitle }} ({{ log.log_date.split(' ')[0] }})</option>
				</b-select>
			</b-field>
		</section>

		<footer class="modal-card-foot">
			<b-button label="ביטול" @click="$parent.close()" />
			<b-button label="טען קופה" type="is-primary" @click="selectedCashier ? ($emit('selected', selectedCashier), $emit('close')) : $refs['logsList'].checkHtml5Validity()" />
		</footer>
	</div>
</template>

<script>
export default {
	props: {
		logsList: Array
	},
	data() {
		return {
			selectedCashier: null
		}
	}
}
</script>
<template>
	<b-field position="is-centered">
		<p class="control" v-if="!clientResult"><span class="button is-static">הכנס לקוח שלא מעוניין להזדהות -</span></p>

		<template v-if="!clientResult || clientResult && (!clientResult.isFree || clientResult.reason == 'צלם')">
			<p class="control">
				<b-tooltip label="תשלום במזומן" type="is-success">
					<b-button
						icon-left="shekel-sign" class="has-text-success has-badge-rounded has-badge-success has-badge-left" :data-badge="clientResult ? clientResult.discountPrice : null"
						@click="clientEnter(clientResult ? clientResult['clientIndex'] : null, 'cash')" @contextmenu.prevent="clientEnter(clientResult ? clientResult['clientIndex'] : null, 'cash')" />
				</b-tooltip>
			</p>

			<!-- <p class="control">
				<b-tooltip label="מחיר מיוחד" type="is-danger">
					<b-button
						icon-left="percent" class="has-text-danger"
						@click="clientEnter(clientResult ? clientResult['clientIndex'] : null, 'special-price')" @contextmenu.prevent="clientEnter(clientResult ? clientResult['clientIndex'] : null, 'special-price')" />
				</b-tooltip>
			</p> -->

			<p class="control">
				<b-tooltip label="תשלום באשראי" type="is-primary">
					<b-button
						icon-left="credit-card" class="has-text-primary"
						@click="clientEnter(clientResult ? clientResult['clientIndex'] : null, 'credit-card')" @contextmenu.prevent="clientEnter(clientResult ? clientResult['clientIndex'] : null, 'credit-card')" />
				</b-tooltip>
			</p>

			<p class="control" v-if="false && (enteredClientsWithPunches.length || clientResult)">
				<b-tooltip
					:label="clientResult ?
							(clientResult.ticketPunches ? 'ניקוב בכרטיסייה' : 'רכישת כרטיסייה' + (enteredClientsWithPunches.length ? ' / ניקוב בכרטיסייה של לקוח אחר' : '')) :
							'ניקוב בכרטיסייה של לקוח אחר'"
					type="is-warning">
						<b-button
							icon-left="ticket-alt" class="has-text-warning has-badge-rounded has-badge-warning has-badge-left" :data-badge="clientResult ? (clientResult.ticketPunches || null) : null"
							@click="clientEnter(clientResult ? clientResult['clientIndex'] : null, 'ticket')" @contextmenu.prevent="clientEnter(clientResult ? clientResult['clientIndex'] : null, 'ticket')" />
				</b-tooltip>

				<b-tooltip v-if="clientResult && clientResult.ticketPunches" :label="'רכישת כרטיסייה' + (enteredClientsWithPunches.length ? ' / ניקוב בכרטיסייה של לקוח אחר' : '')" position="is-left" type="is-danger" class="buy-ticket has-background-danger">
					<a class="has-text-white" @click.stop.prevent="forceBuyTicket(clientResult['clientIndex'])" @contextmenu.stop.prevent="forceBuyTicket(clientResult['clientIndex'])">+</a>
				</b-tooltip>
			</p>
		</template>

		<p class="control">
			<b-tooltip label="כניסה חינם" type="is-link">
				<b-button
					icon-left="door-open" class="has-text-link"
					@click="clientEnter(clientResult ? clientResult['clientIndex'] : null, 'free')" @contextmenu.prevent="clientEnter(clientResult ? clientResult['clientIndex'] : null, 'free')" />
			</b-tooltip>
		</p>

		<!-- <p class="control">
			<b-tooltip label="כניסה  עם נקודות" type="is-info">
				<b-button
					icon-left="award" class="has-text-info"
					@click="clientEnter(clientResult ? clientResult['clientIndex'] : null, 'points')" @contextmenu.prevent="clientEnter(clientResult ? clientResult['clientIndex'] : null, 'points')" />
			</b-tooltip>
		</p> -->
	</b-field>
</template>

<script>
const	nonClientPrefix		 = 'nonClient_';
		// defaultEntrancePrice = 50;

import TicketsModal from '@/components/TicketsModal.vue';
import SpecialPriceModal from '@/components/SpecialPriceModal.vue';
import CreditCardModal from '@/components/CreditCardModal.vue';

export default {
	props: {
		clients: Array,
		enteredClients: Array,
		clientResult: Object
	},
	computed: {
		allClients() {
			return this.clients;
		},

		enteredClientsWithPunches() {
			return this.enteredClients.filter(function(clientObject) {
				return clientObject['ticketPunches'];
			});
		}
	},
	methods: {
		_addClientToEnteredList(enteredClient, cashAdded, paymentMethod) {
			//
			enteredClient['cashPayed']		= cashAdded;
			enteredClient['paymentMethod']	= paymentMethod;
			enteredClient['timeEntered']	= this.$root.timeEntered();

			//
			if (typeof enteredClient['clientIndex'] !== 'undefined') {
				/* this.allClients.splice(enteredClient['clientIndex'], 1);
				this.$emit('update:clients', this.allClients); */

				this.allClients[enteredClient['clientIndex']].isEntered = true;
			}

			//
			let enteredClients = Array.from(this.enteredClients);

			enteredClients.unshift(enteredClient);
			this.$emit('update:enteredClients', enteredClients);

			//
			/* this.lastSearched = this.searchClient;
			this.searchClient = '';
			this.$refs.searchClient.focus(); */
		},

		clientEnter(clientIndex, paymentMethod) {
			var commentTriggered, enteredClient = {}, cashAdded = 0;

			if (clientIndex !== null) {
				//
				// clientIndex = this.searchResults[clientIndex]['clientIndex'];
				Object.assign(enteredClient, this.allClients[clientIndex]);
			} else {
				var lastNonClientEntered = this.enteredClients.filter((clientObject) => {
					return clientObject.isNonClient;
				}).shift(), nonClientId  = lastNonClientEntered ? parseInt(lastNonClientEntered['id'].replace(nonClientPrefix, '')) + 1 : 1;

				enteredClient = {
					'id': nonClientPrefix + nonClientId,
					'name': 'לקוח שלא מעוניין להזדהות (#' + nonClientId + ')',
					'phone': '-',
					'isNonClient': true
				}
			}

			//
			switch (paymentMethod) {
				case ('free'): {
					if (!enteredClient.isFree) {
						commentTriggered = this.$buefy.dialog.prompt({
							message: "הזינו סיבה/הערה לכניסה חינם:",
							confirmText: 'אישור',
							onConfirm: (comment) => {
								enteredClient['comment'] = comment;

								//
								this._addClientToEnteredList(enteredClient, cashAdded, paymentMethod);
							}
						});
					}

					break;
				}

				case ('ticket'): {
					if (enteredClient.ticketPunches) {
						enteredClient['ticketPunches']--;
					} else {
						this.$buefy.modal.open({
							parent: this,
							component: TicketsModal,
							hasModalCard: true,
							props: {
								enteredClient: enteredClient
							}
						});

						return;
					}

					break;
				}

				/* case ('special-price'): {
					this.$buefy.modal.open({
						parent: this,
						component: SpecialPriceModal,
						hasModalCard: true,
						props: {
							enteredClient: enteredClient
						}
					});

					return;
				} */

				case ('credit-card'): {
					this.$buefy.modal.open({
						parent: this,
						component: CreditCardModal,
						hasModalCard: true,
						props: {
							enteredClient: enteredClient
						}
					});

					return;
				}

				case ('cash'): {
					this.$buefy.modal.open({
						parent: this,
						component: SpecialPriceModal,
						hasModalCard: true,
						props: {
							enteredClient: enteredClient
						}
					});

					return;

					/* if (enteredClient.isDiscount) {
						cashAdded = enteredClient.discountPrice;
					} else {
						cashAdded = defaultEntrancePrice;
					} */
				}
			}

			//
			if (event.which == 3 && typeof commentTriggered == 'undefined') {
				this.$buefy.dialog.prompt({
					message: "הזינו הערה עבור הכניסה של " + enteredClient['name'] + ":",
					confirmText: 'אישור',
					inputAttrs: {
						required: false
					},
					onConfirm: (comment) => {
						enteredClient['comment'] = comment;

						//
						this._addClientToEnteredList(enteredClient, cashAdded, paymentMethod);
					},
					onCancel: () => {
						//
						if (paymentMethod == 'ticket') {
							if (cashAdded) {
								delete enteredClient['ticketPunches'];
							} else {
								enteredClient['ticketPunches']++;
							}
						}
					}
				});
			} else if (typeof commentTriggered == 'undefined') {
				//
				this._addClientToEnteredList(enteredClient, cashAdded, paymentMethod);
			}
		},

		forceBuyTicket(clientIndex) {
			this.$buefy.modal.open({
				parent: this,
				component: TicketsModal,
				hasModalCard: true,
				props: {
					enteredClient: this.allClients[clientIndex]
				}
			});
		}
	}
}
</script>
<template>
	<div class="modal-card" style="width: 600px">
		<header class="modal-card-head">
			<p class="modal-card-title">כניסה עם כרטיס אשראי</p>
		</header>

		<section class="modal-card-body">
			<b-field position="is-centered">
				<p class="control"><span class="button is-static">מחיר:</span></p>
				<b-radio-button v-for="price in pricesList" :key="price" v-model="moneyPayed" :native-value="price" type="is-success">
					<span>{{ price }}</span>
					<b-icon icon="shekel-sign" size="is-small"></b-icon>
				</b-radio-button>
				<p class="control"><span class="button is-static">או:</span></p>
				<b-field label="סכום אחר" label-position="on-border">
					<b-input v-model.number="moneyPayed" type="number" icon-right="shekel-sign" onfocus="this.select()"></b-input>
				</b-field>
			</b-field>

			<b-field position="is-centered" class="my-5">
				<p class="control"><span class="button is-static">אמצעי:</span></p>
				<b-select placeholder="בחרו סיבה..." v-model="creditCardMethod" expanded>
					<option value="ביט">ביט</option>
					<option value="פייבוקס">פייבוקס</option>
					<option value="העברה בנקאית">העברה בנקאית</option>
				</b-select>
				<p class="control"><span class="button is-static">או:</span></p>
				<b-field label="אחר..." label-position="on-border">
					<b-input v-model="creditCardMethod" placeholder="הזינו אמצעי תשלום..." onfocus="this.select()"></b-input>
				</b-field>
			</b-field>

			<template v-if="moneyPayed != pricesList[0]">
				<b-field position="is-centered" class="my-5">
					<p class="control"><span class="button is-static">סיבה:</span></p>
					<b-select placeholder="בחרו סיבה..." v-model="specialPriceReason">
						<option value="כניסה בזוג">כניסה בזוג</option>
						<option value="חייל/סטודנט">חייל/סטודנט</option>
						<option value="שיעור בסיס">שיעור בסיס</option>
						<option value="חבר מביא חבר לשיעור בסיס">חבר מביא חבר לשיעור בסיס</option>
						<option value="נהג צפון/דרום/ירושלים">נהג צפון/דרום/ירושלים</option>
					</b-select>
					<p class="control"><span class="button is-static">או:</span></p>
					<b-field label="סיבה ידנית" label-position="on-border">
						<b-input v-model="specialPriceReason" placeholder="הזינו סיבה..." onfocus="this.select()"></b-input>
					</b-field>
				</b-field>

				<div v-if="false && parseInt(enteredClient['id'])">
					<b-checkbox v-model="isPermanent" class="help">סמנו אם ההנחה היא קבועה</b-checkbox>
					<b-field grouped v-if="isPermanent">
						<p class="control"><span class="button is-static">תוקף ההנחה:</span></p>
						<p class="control">
							<b-datepicker type="month" icon-prev="angle-right" icon-next="angle-left" inline :month-names="monthNames" :min-date="minDate" v-model="discountUntil" />
						</p>
					</b-field>
				</div>
			</template>

			<b-field label="הערה נוספת" label-position="on-border" class="mt-5">
				<b-input v-model="enteredClient['comment']" icon-right="comment-dots" :placeholder="'הזינו הערה עבור הכניסה של ' + enteredClient['name']" onfocus="this.select()"></b-input>
			</b-field>
		</section>

		<footer class="modal-card-foot">
			<button class="button" type="button" @click="$emit('close')">ביטול</button>
			<button class="button is-primary" @click="confirmAction()">אישור</button>
		</footer>
	</div>
</template>

<script>
const pricesList = [50, 100, 45, 25];

export default {
	props: ['enteredClient'],
	data() {
		return {
			moneyPayed: pricesList[0],
			pricesList: pricesList,
			creditCardMethod: 'ביט',
			specialPriceReason: 'כניסה בזוג'
		}
	},
	methods: {
		confirmAction() {
			//
			if (this.creditCardMethod) {
				this.enteredClient['method']	 = this.creditCardMethod;
				this.enteredClient['moneyPayed'] = this.moneyPayed;

				//
				if (this.specialPriceReason) {
					if (this.moneyPayed != pricesList[0]) this.enteredClient['reason'] = this.specialPriceReason;

					this.$parent.$parent._addClientToEnteredList(this.enteredClient, this.moneyPayed, 'cash');
					this.$parent.close();
				} else {
					this.$buefy.toast.open({
						message: 'חובה לבחור סיבה למחיר המיוחד.',
						type: 'is-danger'
					});
				}

				this.$parent.$parent._addClientToEnteredList(this.enteredClient, 0, 'credit-card');
				this.$parent.close();
			} else {
				this.$buefy.toast.open({
					message: 'חובה לבחור אמצעי תשלום.',
					type: 'is-danger'
				});
			}
		}
	}
}
</script>
import Vue from 'vue'
import App from './App.vue'

import Buefy from 'buefy';
import './assets/main.scss';
import './assets/main.css';
import './assets/bulma-badge.min.css';
Vue.use(Buefy)

window.axios = require('axios');

Vue.config.productionTip = false

new Vue({
	methods: {
		timeEntered() {
			var currentTime = new Date();

			return currentTime.getHours() + ":" + ("0" + currentTime.getMinutes()).slice(-2) + ":" + ("0" + currentTime.getSeconds()).slice(-2);
		}
	},
	render: h => h(App),
	created() {
		window.axios.defaults.baseURL = process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_SERVER_PATH : process.env.BASE_URL
	}
}).$mount('#app')

<template>
	<div class="modal-card" style="width: auto">
		<!--header class="modal-card-head">
			<p class="modal-card-title">{{ dialogTitle }}</p>
		</header-->

		<section class="modal-card-body">
			<b-tabs :animated="false" type="is-boxed" v-model="currentTab" ref="ticketActionTabs">
				<b-tab-item label="רכישת כרטיסייה" icon="coins" v-if="'clientIndex' in enteredClient">
					<div class="subtitle">האם לרכוש כרטייסיה עבור {{ enteredClient['name'] }}?</div>

					<b-field class="has-addons-centered">
						<b-radio-button v-model="ticketType" native-value="class" type="is-warning" :disabled="!isClassTicketAllowed" @click.native="!isClassTicketAllowed ? bypassTicketAllowance('class') : null">
							<b-icon icon="chalkboard-teacher"></b-icon>
							<span ref="ticketLabel[class]">כרטיסיית שיעורים</span>
						</b-radio-button>
						<b-radio-button v-model="ticketType" native-value="party" type="is-warning">
							<b-icon icon="headphones-alt"></b-icon>
							<span ref="ticketLabel[party]">כרטיסיית מסיבה</span>
						</b-radio-button>
					</b-field>

					<b-field class="has-addons-centered">
						<b-radio-button v-model="ticketPrice" :native-value="ticketPricesList[ticketType][0]" type="is-success">
							<span>{{ ticketPricesList[ticketType][0] }}</span>
							<b-icon icon="shekel-sign" size="is-small"></b-icon>
						</b-radio-button>
						<b-radio-button v-model="ticketPrice" :native-value="ticketPricesList[ticketType][1]" type="is-success">
							<span>{{ ticketPricesList[ticketType][1] }}</span>
							<b-icon icon="shekel-sign" size="is-small"></b-icon>
						</b-radio-button>
						<b-field label="סכום אחר" label-position="on-border" style="width: 25%">
							<b-input v-model.number="ticketPrice" type="number" icon-right="shekel-sign" onfocus="this.select()"></b-input>
						</b-field>
					</b-field>

					<div class="field">
						<b-checkbox v-model="punchTicket" type="is-success" class="help">ניקוב בכרטיסייה</b-checkbox>
					</div>
					<div class="field">
						<b-checkbox v-model="isCreditCard" class="help">סמנו במידה והרכישה בוצעה בכרטיס אשראי</b-checkbox>
					</div>
				</b-tab-item>

				<b-tab-item label="ניקוב מלקוח אחר" icon="check-double" v-if="$parent.$parent.enteredClientsWithPunches.length">
					<div class="subtitle">באיזו כרטייסיה להשתשמש עבור הכניסה של {{ enteredClient['name'] }}?</div>

					<b-select expanded placeholder="בחרו לקוח..." v-model="selectedTicket">
						<option v-for="(clientObject, index) in $parent.$parent.enteredClientsWithPunches" :value="index" :key="index">
							{{ clientObject.name + ' (' + clientObject.ticketPunches + ')' }}
						</option>
					</b-select>
				</b-tab-item>
			</b-tabs>

			<b-field label="הערה" label-position="on-border">
				<b-input v-model="enteredClient['comment']" icon-right="comment-dots" :placeholder="'הזינו הערה עבור הכניסה של ' + enteredClient['name']" onfocus="this.select()"></b-input>
			</b-field>
		</section>

		<footer class="modal-card-foot">
			<button class="button" type="button" @click="cancelTicketAction()">ביטול</button>
			<button class="button is-primary" @click="confirmTicketAction()">אישור</button>
		</footer>
	</div>
</template>

<script>
const ticketPricesList  = {
	'class': [160, 140],
	'party': [180, 160]
};

export default {
	props: ['enteredClient'],
	data() {
		return {
			currentTab: 0,

			punchTicket: true,
			isCreditCard: false,
			ticketType: 'party',
			ticketPrice: ticketPricesList['party'][0],
			ticketPricesList: ticketPricesList,
			ticketAllowanceBypassed: false,

			selectedTicket: null
		}
	},
	computed: {
		isClassTicketAllowed() {
			// TODO: switch sign in production
			return false;
			/* return this.ticketAllowanceBypassed ? true : Date.now() < this.$parent.$parent.classStartTime.getTime(); */
		}
	},
	methods: {
		bypassTicketAllowance(ticketType) {
			this.$buefy.snackbar.open({
				message: 'האם לאשר רכישה של ' + this.$refs['ticketLabel[' + ticketType + ']'].innerText + '?',
				type: 'is-danger',
				position: 'is-top',
				duration: 5000,
				actionText: 'אישור',
				onAction: () => {
					this.ticketAllowanceBypassed = true
				}
			});
		},

		confirmTicketAction() {
			//
			if (this.currentTab == 1 || !this.enteredClient['clientIndex']) {
				if (this.selectedTicket !== null) {
					var punchedTicketClient = this.$parent.$parent.enteredClientsWithPunches[this.selectedTicket];

					//
					punchedTicketClient['ticketPunches']--;

					//
					this.enteredClient['reason']				= 'ניקוב מ' + punchedTicketClient['name'];
					this.enteredClient['punchedTicketClientId'] = punchedTicketClient['id'];

					this.$parent.$parent._addClientToEnteredList(this.enteredClient, 0, 'double-punch');
				}

				this.$parent.close();
				return;
			}

			//
			if (this.punchTicket) {
				//
				this.enteredClient['ticketPunches'] = 3;
				this.enteredClient['isNewTicket']	= true;

				this.$parent.$parent._addClientToEnteredList(this.enteredClient, this.isCreditCard ? 0 : this.ticketPrice, 'ticket');
			} else {
				//
				this.enteredClient['ticketPunches'] = 4 + (this.enteredClient['ticketPunches'] || 0);

				//
				this.$set(this.$parent.$parent.allClients, this.enteredClient['clientIndex'], this.enteredClient);

				this.$buefy.toast.open({
                    message: 'כרטיסייה נרכשה בהצלחה עבור ' + this.enteredClient['name'] + ', מספר טלפון: ' + this.enteredClient['phone'] + '.',
                    type: 'is-success'
				});
			}

			//
			this.$parent.close();
		},

		cancelTicketAction() {
			this.$parent.close();
		}
	},
	created() {
		// this.ticketType = Date.now() < this.$parent.$parent.classStartTime.getTime() ? 'class' : 'party';
	},
	watch: {
		ticketType() {
			//
			this.ticketPrice = this.enteredClient['isDiscount'] ? ticketPricesList[this.ticketType][1] : ticketPricesList[this.ticketType][0];
		}
	}
}
</script>
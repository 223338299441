<template>
	<div class="modal-card" style="width: auto">
		<header class="modal-card-head">
			<p class="modal-card-title">סינון כניסות</p>
		</header>

		<section class="modal-card-body">
			<b-field>
				<b-select v-model="selectedFilter" required icon="cash-register" placeholder="בחרו סוג כניסה..." ref="filtersList">
					<option v-for="(filter, key) in filters" :key="key" :value="key">{{ filter }}</option>
				</b-select>
			</b-field>
		</section>

		<footer class="modal-card-foot">
			<b-button label="ביטול" @click="$parent.close()" />
			<b-button label="סינון" type="is-primary" @click="selectedFilter ? ($emit('selected', selectedFilter), $emit('close')) : $refs['filtersList'].checkHtml5Validity()" />
		</footer>
	</div>
</template>

<script>
export default {
	props: {
		logsList: Array
	},
	data() {
		return {
			filters: {
				cash: 'מזומן',
				'ביט': 'ביט',
				'פייבוקס': 'פייבוקס',
				free: 'חינם'
			},
			selectedFilter: null
		}
	}
}
</script>
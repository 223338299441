<template>
	<div class="modal-card" style="width: 600px;">
		<header class="modal-card-head">
			<p class="modal-card-title">תשלום במזומן</p>
		</header>

		<section class="modal-card-body">
			<!-- <div class="subtitle">בחרו/הזינו סיבה למחיר המיוחד עבור הכניסה של {{ enteredClient['name'] }}:</div> -->
			<b-field position="is-centered">
				<p class="control"><span class="button is-static">מחיר:</span></p>
				<b-radio-button v-for="(price, i) in specialPricesList" :key="i" v-model="cashPayed" :native-value="price" type="is-success">
					<span>{{ price }}</span>
					<b-icon icon="shekel-sign" size="is-small"></b-icon>
				</b-radio-button>
				<p class="control"><span class="button is-static">או:</span></p>
				<b-field label="סכום אחר" label-position="on-border">
					<b-input v-model.number="cashPayed" type="number" icon-right="shekel-sign" onfocus="this.select()"></b-input>
				</b-field>
			</b-field>

			<template v-if="cashPayed != specialPricesList[0]">
				<b-field position="is-centered" class="my-5">
					<p class="control"><span class="button is-static">סיבה:</span></p>
					<b-select placeholder="בחרו סיבה..." v-model="specialPriceReason">
						<option value="כניסה בזוג">כניסה בזוג</option>
						<option value="חייל/סטודנט">חייל/סטודנט</option>
						<option value="שיעור בסיס">שיעור בסיס</option>
						<option value="חבר מביא חבר לשיעור בסיס">חבר מביא חבר לשיעור בסיס</option>
						<option value="נהג צפון/דרום/ירושלים">נהג צפון/דרום/ירושלים</option>
					</b-select>
					<p class="control"><span class="button is-static">או:</span></p>
					<b-field label="סיבה ידנית" label-position="on-border">
						<b-input v-model="specialPriceReason" placeholder="הזינו סיבה..." onfocus="this.select()"></b-input>
					</b-field>
				</b-field>

				<div v-if="false && parseInt(enteredClient['id'])">
					<b-checkbox v-model="isPermanent" class="help">סמנו אם ההנחה היא קבועה</b-checkbox>
					<b-field grouped v-if="isPermanent">
						<p class="control"><span class="button is-static">תוקף ההנחה:</span></p>
						<p class="control">
							<b-datepicker type="month" icon-prev="angle-right" icon-next="angle-left" inline :month-names="monthNames" :min-date="minDate" v-model="discountUntil" />
						</p>
					</b-field>
				</div>
			</template>

			<b-field label="הערה נוספת" label-position="on-border" class="mt-5">
				<b-input v-model="enteredClient['comment']" icon-right="comment-dots" :placeholder="'הזינו הערה עבור הכניסה של ' + enteredClient['name']" onfocus="this.select()"></b-input>
			</b-field>
		</section>

		<footer class="modal-card-foot">
			<button class="button" type="button" @click="cancelAction()">ביטול</button>
			<button class="button is-primary" @click="confirmAction()">אישור</button>
		</footer>
	</div>
</template>

<script>
const specialPricesList = [50, 100, 45, 25];

export default {
	props: ['enteredClient'],
	data() {
		return {
			cashPayed: specialPricesList[0],
			specialPricesList: specialPricesList,
			specialPriceReason: 'כניסה בזוג',

			isPermanent: false,
			discountUntil: null,
			minDate: new Date(),
			monthNames: ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר']
		}
	},
	methods: {
		confirmAction() {
			if (this.isPermanent && this.discountUntil) {
				this.enteredClient['isDiscount']	= true;
				this.enteredClient['discountUntil'] = Date.parse(this.discountUntil) / 1000;
			}

			//
			if (this.specialPriceReason) {
				if (this.cashPayed != specialPricesList[0]) this.enteredClient['reason'] = this.specialPriceReason;

				this.$parent.$parent._addClientToEnteredList(this.enteredClient, this.cashPayed, 'cash');
				this.$parent.close();
			} else {
				this.$buefy.toast.open({
					message: 'חובה לבחור סיבה למחיר המיוחד.',
					type: 'is-danger'
				});
			}
		},
		cancelAction() {
			this.$parent.close();
		}
	},
	created() {
		if (this.enteredClient['isDiscount']) {
			this.isPermanent	= true;
			this.discountUntil	= new Date(this.enteredClient['discountUntil'] * 1000);
		}
	}
}
</script>
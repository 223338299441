<template>
  <div v-cloak id="app" class="section" :class="{'is-free-list': freeListOnly}">
		<!-- <div>@loadFreeList</div> -->

		<template v-if="freeListOnly">
			<div class="buttons is-centered">
				<a class="button is-success" :class="{'is-loading': isLoadingClients}" @click="getAllClientsFromDB()" v-if="!dataIsPulled">משיכת נתונים מהמערכת</a>
				<b-field style="margin: 0;" :style="{visibility: selectedClient ? 'visible' : 'hidden'}">
					<b-select v-model="freeReason" placeholder="בחרו סיבה">
						<option v-for="(option, i) in freeReasons" :value="option" :key="i">
							{{ option }}
						</option>
					</b-select>
					<b-input v-model="freeReason" onfocus="this.select()" placeholder="סיבה חדשה"></b-input>
					<p class="control" :style="{opacity: freeReason ? 1 : 0}"><button class="button is-link" @click="addFreeClient()">הוספת לקוח לרשימת חינמים</button></p>
				</b-field>
				<button v-if="freeClientsToDelete.length" class="button is-danger" @click="removeFreeClient()">הסר לקוחות מסומנים מרשימת חינמים</button>
			</div>

			<b-field style="margin-top: 20px">
				<div class="control">
					<button class="button is-danger" @click="clearSearch()" title="נקה חיפוש" :disabled="!searchClient">
						<i class="fas fa-times"></i>
					</button>
				</div>
				<!-- <b-input expanded :icon="getIconForSearchInput()" v-model="searchClient" ref="searchClient" placeholder="הזן שם או מספר טלפון" @keydown.native="validateSearchInput()" :disabled="!allClients.length"></b-input> -->
				<b-autocomplete open-on-focus ref="autocomplete" expanded v-model="searchClient" :data="searchResults" :custom-formatter="searchFormat" placeholder="הזן שם או מספר טלפון" :icon="getIconForSearchInput()" @select="option => selectedClient = option">
					<template slot="empty">אין תוצאות</template>
				</b-autocomplete>
				<div class="control">
					<button class="button is-info" @click="addClient()" title="הוספת לקוח חדש" :disabled="!searchClient || clientExists">
						<i class="fas fa-plus"></i>
					</button>
				</div>
			</b-field>

			<div class="columns is-multiline">
				<div class="column is-one-third" v-for="(freeReason, i) in freeReasons" :key="i">
					<table v-if="freeClients" class="table is-fullwidth is-striped is-hoverable">
						<thead>
							<tr>
								<th align="center" colspan="2" width="50%">שם הלקוח</th>
								<th align="center">מס' טלפון</th>
								<th align="center">סיבה</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(result, i) in freeClients" :key="i">
								<template v-if="result.reason == freeReason || freeReason == 'בני/בנות זוג' && result.reason.indexOf('זוג של') > -1">
									<td><b-checkbox v-model="freeClientsToDelete" :native-value="result.id"></b-checkbox></td>
									<td align="center">
										<span>{{ result.name }}</span>
									</td>
									<td align="center">{{ result.phone }}</td>
									<td align="center">
										<span v-if="!isEdit[result.id]" @click="setEdit(result.id, freeReason)">{{ result.reason }}</span>
										<b-select v-if="isEdit[result.id]" v-model="isEdit[result.id]" @input="changeFreeReason(result.id)">
											<option v-for="(option, j) in freeReasons" :value="option" :key="j">{{ option }}</option>
										</b-select>
										<b-input onfocus="this.select()" v-if="isEdit[result.id]" v-model="isEdit[result.id]" style="margin: 5px 0"></b-input>
										<a v-if="isEdit[result.id]" class="button is-link" @click="changeFreeReason(result.id)">שינוי סיבה</a>
										<a v-if="isEdit[result.id]" class="button is-danger is-small" @click="setEdit(result.id, freeReason)">X</a>
									</td>
								</template>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</template>

		<div class="columns" v-else>
			<div class="column">
				<b-tabs type="is-boxed" :animated="false" v-model="currentTab">
					<b-tab-item class="has-text-centered">
						<template slot="header">
							<b-icon pack="fas" size="is-small" icon="cash-register" />
							<span class="is-flex is-align-items-baseline">
								<span ref="cashierTitle">{{ cashierTitle || "זה בצ'אטה ניישן!" }}</span>
								<span class="content is-small mr-1">{{ cashierDate }}</span>
							</span>
							<b-icon v-if="dataIsPulled" size="is-small" type="is-dark" icon="edit" class="mr-3" @click.native="updateCashierName()" />
						</template>

						<b-field class="mt-6">
							<div class="control">
								<b-tooltip label="נקה חיפוש" type="is-danger">
									<b-button type="is-danger" icon-left="times" @click="clearSearch" :disabled="!searchClient" />
								</b-tooltip>
							</div>

							<b-input expanded :icon-right="getIconForSearchInput()" v-model="searchClient" ref="searchClient" placeholder="הזן שם או מספר טלפון" @keydown.native="validateSearchInput" :disabled="!dataIsPulled" />

							<div class="control">
								<b-tooltip label="הוספת לקוח חדש" type="is-info">
									<b-button type="is-info" icon-left="plus" @click="addClient" :disabled="!searchClient || clientExists" />
								</b-tooltip>
							</div>
						</b-field>

						<div class="mt-6" v-if="dataIsPulled && !searchClient">
							<ClientActions :clients.sync="allClients" :entered-clients.sync="enteredClients" />
						</div>

						<table v-if="searchResults && searchResults.length" class="table is-striped is-hoverable is-fullwidth">
							<thead>
								<tr>
									<th width="20%"></th>
									<th align="center" width="30%">שם הלקוח</th>
									<th align="center" width="30%">מס' טלפון</th>
									<th align="center" width="100%">פעולות</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="result in searchResults" :key="result.id" :class="{'has-background-link has-text-white': result.isFree, 'has-background-warning': result.ticketPunches && !result.isFree }" :style="result.isEntered ? 'opacity: .5' : ''">
									<td class="px-0">
										<b-tooltip :label="isEditingClient != result.id ? 'עריכת לקוח' : 'שמור'" :type="isEditingClient != result.id ? 'is-info' : 'is-success'">
											<b-button
												:class="{'has-text-info': isEditingClient != result.id, 'has-text-success': isEditingClient == result.id}"
												:icon-left="isEditingClient == result.id ? 'check' : 'edit'" size="is-small"
												@click="isEditingClient == result.id ? saveClient() : (isEditingClient = result.id)" />
										</b-tooltip>

										<b-button disabled size="is-small" icon-left="times" class="has-text-danger mr-1" />
									</td>
									<td align="center">
										<span v-if="isEditingClient !== result.id" v-html="result.name.replaceAll(searchClient, '<b>' + searchClient + '</b>')" />
										<b-input v-else v-model="result.name" lazy @keydown.native="$event.key == 'Enter' ? saveClient() : void 0" />
									</td>
									<td align="center">
										<span v-if="isEditingClient !== result.id" v-html="result.phone ? result.phone.replaceAll(searchClient, '<b>' + searchClient + '</b>') : '-'" />
										<b-input v-else v-model="result.phone" lazy @keydown.native="$event.key == 'Enter' ? saveClient() : void 0" />
									</td>
									<td>
										<ClientActions v-if="!result.isEntered" :clients.sync="allClients" :entered-clients.sync="enteredClients" :client-result="result" />
										<b v-else>הלקוח כבר נכנס.</b>
									</td>
								</tr>
							</tbody>
						</table>

						<div v-if="searchClient && searchResults && !searchResults.length" class="content has-text-centered is-medium">
							<span class="has-text-weight-semibold has-text-danger">לא נמצא לקוח.</span>
						</div>
					</b-tab-item>
					<!-- <b-tab-item :label="tabsList[1]" icon-pack="fas" icon="door-open" v-if="freeClients">
						<table v-if="freeClients" class="table is-striped is-hoverable is-fullwidth">
							<thead>
								<tr>
									<th align="center" width="50%">שם הלקוח</th>
									<th align="center">מס' טלפון</th>
									<th align="center">סיבה</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="result in freeClients">
									<td align="center">
										<span>{{ result.name }}</span>
										<button class="button is-small" title="עריכת לקוח">
											<span class="icon is-small has-text-info"><i class="fas fa-edit"></i></span>
										</button>
									</td>
									<td align="center">{{ result.phone }}</td>
									<td align="center">{{ result.reason }}</td>
								</tr>
							</tbody>
						</table>
					</b-tab-item> -->
					<b-tab-item :label="tabsList[2]" icon-pack="fas" icon="hand-holding-usd" v-if="cashWithdrawals.length">
						<table v-if="cashWithdrawals.length" class="table is-striped is-hoverable is-fullwidth">
							<thead>
								<tr>
									<th align="center" colspan="2">סכום שהוצא</th>
									<th align="center">סיבת הוצאה</th>
									<th align="center">שעה</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(result, index) in cashWithdrawals" :key="index">
									<td align="center" width="1%">
										<button class="button is-small" title="מחיקת רשומה" @click="removeWithdraw(index)">
											<span class="icon is-small has-text-danger"><i class="fas fa-times"></i></span>
										</button>
									</td>
									<td align="center">{{ result.cashWithdrawn }}</td>
									<td align="center">{{ result.withdrawReason }}</td>
									<td align="center">{{ result.withdrawTime }}</td>
								</tr>
							</tbody>
						</table>
					</b-tab-item>
				</b-tabs>
			</div>

			<div class="column has-text-centered">
				<div class="buttons is-centered">
					<a class="button is-success" :class="{'is-loading': isLoadingClients}" v-if="!dataIsPulled" @click="updateCashierName(true)">פתיחת קופה חדשה</a>
					<a class="button is-info" :class="{'is-loading': isLoadingClients}" v-else @click="updateData()">{{ isHistory ? 'עדכון קופה במערכת' : 'סגירת קופה' }}</a>
					<a class="button is-warning" v-if="isHistory || !dataIsPulled" @click="loadLog()">{{ isHistory ? 'צפייה בקופה אחרת' : 'צפייה בהיסטוריית קופה' }}</a>
					<a class="button is-danger is-flex-direction-column" style="height: auto;" v-if="isHistory" @click="clearCashier(true)">
						יציאה מהקופה <b>"{{ isHistory.cashierTitle }}"</b>
					</a>
				</div>

				<table v-if="enteredClients && enteredClients.length" class="table is-striped is-hoverable is-fullwidth content is-small">
					<thead>
						<tr valign="middle">
							<!-- <th width="12%"></th> -->
							<th align="center" colspan="2">
								שם הלקוח

								<b-field class="is-inline-block mr-2">
									<b-input size="is-small" icon-right="search" v-model="searchEntered" />

									<b-field>
										<b-button icon-left="eraser" size="is-small" type="is-danger" @click="searchEntered =''" />
									</b-field>
								</b-field>
							</th>
							<th align="center">מס' טלפון</th>
							<th align="center">זמן כניסה</th>
							<th align="center">
								<div class="is-flex is-align-items-center is-justify-content-center">
									אופן תשלום
									<b-button icon-left="filter" size="is-small" :type="entryFilter ? 'is-success' : ''" class="mr-2" @click="openFiltersModal" />
								</div>
							</th>
							<th align="center" width="25%">הערה</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(result, clientIndex) in filteredEnteredClients" :key="clientIndex">
							<td>
								<template v-if="isEditingClient != result.id">
									<b-tooltip type="is-danger" position="is-right" label="הסרת לקוח מהכניסות">
										<b-button size="is-small" icon-left="times" class="has-text-danger" @click="removeClient(clientIndex)" />
									</b-tooltip>

									<b-tooltip :active="false" type="is-info" position="is-left" label="עריכת כניסת לקוח" class="mr-1">
										<b-button disabled size="is-small" icon-left="edit" class="has-text-info" />
									</b-tooltip>
								</template>

								<b-button v-else label="שמור" type="is-success" size="is-small" @click="saveClient" />
							</td>
							<td align="center" style="white-space: nowrap">
								<b-tooltip label="עריכת לקוח" :active="!result.isNonClient && isEditingClient != result.id" type="is-dark" :dashed="!result.isNonClient && isEditingClient != result.id">
									<span v-if="isEditingClient != result.id" :class="{'is-clickable': !result.isNonClient}" @click="isEditingClient = result.id">{{ result.name }}</span>
									<b-input v-else v-model="result.name" size="is-small" style="width: 7em" @keydown.native="$event.key == 'Enter' ? saveClient() : void 0" />
								</b-tooltip>
							</td>
							<td align="center">
								<span v-if="isEditingClient != result.id">{{ result.phone }}</span>
								<b-input v-else v-model="result.phone" size="is-small" style="width: 6em" @keydown.native="$event.key == 'Enter' ? saveClient() : void 0" />
							</td>
							<td align="center">{{ result.timeEntered }}</td>
							<!-- <td align="center">{{ (result.paymentMethod + ' '+ result.cashPayed) }}</td> -->
							<td align="center" v-html="showPaymentMethodIcon(result.paymentMethod, result.cashPayed || result.moneyPayed)"></td>
							<td class="content is-small has-text-centered is-clickable" @click="addComment(clientIndex)">
								<b-tooltip type="is-dark" :label="(!result.comment ? 'הוסף' : 'עריכת') + ' הערה עבור הכניסה של ' + result.name" multilined size="is-small" style="width: 100%;">
									<span class="tag" :class="{'is-info': result.reason, 'is-danger': result.method}" v-if="result.reason || result.method">{{ result.reason || result.method }}</span>
									<span class="is-block is-italic has-text-right" style="height: 100%;">{{ result.comment || (result.reason || result.method ? '' : '&nbsp;') }}</span>
								</b-tooltip>
							</td>
						</tr>
					</tbody>
				</table>

				<nav class="level mt-6" :style="!dataIsPulled ? 'opacity: .5' : ''">
					<div class="level-item is-clickable" @click="updateInitialCash()" :style="!dataIsPulled ? 'pointer-events: none !important' : ''">
						<b-tooltip label="שינוי מזומן התחלתי" type="is-dark">
							<div>
								<p class="heading">מזומן התחלתי</p>
								<p class="title">{{ initialCash }}</p>
							</div>
						</b-tooltip>
					</div>
					<div class="level-item">
						<div>
							<p class="heading">מזומן רכישות</p>
							<p class="title">{{ purchaseCash }}</p>
						</div>
					</div>
					<div class="level-item is-clickable" :class="{'has-text-danger': withdrawnCash}" @click="withdrawCash" :style="!dataIsPulled ? 'pointer-events: none !important' : ''">
						<b-tooltip type="is-danger" label="הוצאת מזומן מהקופה">
							<div>
								<p class="heading">מזומן שהוצא מהקופה</p>
								<p class="title">{{ withdrawnCash }}</p>
							</div>
						</b-tooltip>
					</div>
					<div class="level-item has-text-success">
						<div class="is-relative">
							<p class="heading">מזומן בקופה</p>
							<p class="title">{{ cashierCash }}</p>
							<p v-if="creditCardMoney" class="help mt-2" style="position: absolute; right: 50%; white-space: nowrap; transform: translateX(50%)">
								<u>ביט</u>:
								<b>{{ getTotalPerMethod('ביט') }}</b>
								<br />
								<u>פייבוקס</u>:
								<b>{{ getTotalPerMethod('פייבוקס') }}</b>
							</p>
						</div>
					</div>
					<div class="level-item has-text-info">
						<div class="is-relative">
							<p class="heading">סה"כ נכנסו</p>
							<p class="title">{{ enteredClients.length + coupledClients.length }}</p>
							<p v-if="freeEntrances.length" class="help mt-2" style="position: absolute; right: 50%; white-space: nowrap; transform: translateX(50%)">
								<u>מתוכם חינמים</u>:
								<b>{{ freeEntrances.length }}</b>
							</p>
						</div>
					</div>
				</nav>
			</div>
		</div>

		<!-- <b-field id="class-clock" label="שעת תחילת שיעורים" label-position="on-border" v-if="!freeListOnly">
			<b-clockpicker v-model="classStartTime" rounded icon="user-clock" hour-format="24" hours-label="שעה" minutes-label="דקות" custom-class="has-text-centered">
			</b-clockpicker>
		</b-field> -->
  </div>
</template>

<script>
/* eslint vue/no-side-effects-in-computed-properties: 0 */
const startTime = new Date();

import ClientActions from '@/components/ClientActions.vue';
import LoadLogModal from '@/components/LoadLog.vue';
import FiltersModal from '@/components/FiltersModal.vue';

export default {
	components: {
		ClientActions
	},
	data() {
		return {
			// @TEMP
			freeListOnly: location.search == '?change-free-list=1',
			selectedClient: null,
			freeReason: '',
			freeClientsToDelete: [],
			isEdit: {},

			//
			dataIsPulled: localStorage['lastUpdated'] || false,
			isLoadingClients: false,
			isHistory: localStorage['isHistory'] ? JSON.parse(localStorage['isHistory']) : null,

			//
			currentTab: 0,
			tabsList: [
				null, // reserved for cashier's title
				'רשימת חינמים',
				'הוצאות כסף מהקופה'
			],

			//
			classStartTime: new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), 22, 20),
			cashierTitle: localStorage['cashierTitle'],
			initialCash: parseInt(localStorage['initialCash']) || 0,
			cashWithdrawals: JSON.parse(localStorage['cashWithdrawals'] || '[]'),

			//
			searchClient: '',
			lastSearched: '',
			clientExists: false,
			isEditingClient: null,

			//
			allClients: JSON.parse(localStorage['allClients'] || '[]'),
			enteredClients: JSON.parse(localStorage['enteredClients'] || '[]'),
			searchEntered: '',
			entryFilter: null
		}
	},
	computed: {
		cashierDate() {
			return this.isHistory ? new Date(this.isHistory['log_date']).toLocaleDateString('en-GB') : new Date().toLocaleDateString('en-GB');
		},

		purchaseCash() {
			return this.enteredClients.map(function(currentRow) {
				return currentRow['cashPayed'];
			}).reduce(function(total, cashPayed) {
				return total + cashPayed;
			}, 0);
		},

		withdrawnCash() {
			return this.cashWithdrawals.map((item) => item.cashWithdrawn).reduce((total, cashWithdrawn) => total + cashWithdrawn, 0);
		},

		cashierCash() {
			return (this.initialCash + this.purchaseCash - this.withdrawnCash);
		},

		creditCardMoney() {
			let creditCardPayed = this.enteredClients.filter(client => client.moneyPayed).map(client => client.moneyPayed);

			return creditCardPayed.length ? creditCardPayed.reduce((a, b) => a + b) : 0;
		},

		freeEntrances() {
			return this.enteredClients.filter(function(clientObject) {
				return clientObject.paymentMethod == 'free';
			});
		},

		/** ################################# */
		freeClients() {
			return this.allClients.length ? this.allClients.filter(function(clientObject) {
				return clientObject.isFree;
			}).sort((a, b) => (a.name > b.name) ? 1 : -1) : [];
		},

		freeReasons() {
			return this.freeClients.map(function(clientObject) {
				return clientObject.reason.indexOf('זוג של') > -1 ? 'בני/בנות זוג' : clientObject.reason;
			}).filter((v, i, a) => a.indexOf(v) === i).sort((a, b) => {
				a = this.freeClients.filter((clientObject) => { return a == 'בני/בנות זוג' ? clientObject.reason.indexOf('זוג של') > -1 : clientObject.reason == a});
				b = this.freeClients.filter((clientObject) => { return b == 'בני/בנות זוג' ? clientObject.reason.indexOf('זוג של') > -1 : clientObject.reason == b});

				if (a.length < b.length) return 1;

				return -1;
			});
		},
		/** ################################# */

		searchResults() {
			//
			var checkedParam = isNaN(this.searchClient) ? 'name' : 'phone',
				allClients	 = this.allClients.map((clientObject, clientIndex) => Object.assign(clientObject, {clientIndex: clientIndex}));

			//
			this.clientExists = false;

			if (this.searchClient.length > 5 || this.searchClient.substr(0, 2) != '05' && this.searchClient.length > 2) {
				return allClients.filter(function(clientObject) {
					//
					/* clientObject['name']  = clientObject['name'].replace(/<[^>]*>?/gm, '');
					clientObject['phone'] = clientObject['phone'].replace(/<[^>]*>?/gm, ''); */

					if (clientObject[checkedParam] && clientObject[checkedParam].indexOf(this.searchClient) > -1) {
						//
						if (checkedParam == 'phone' && clientObject[checkedParam] == this.searchClient) this.clientExists = true;

						// @TEMP
						if (this.freeListOnly && clientObject.isFree) return false;

						//
						/* if (!this.freeListOnly) {
							var replaceRegex = new RegExp(this.searchClient, 'gi');
							clientObject[checkedParam] = clientObject[checkedParam].replace(replaceRegex, "<b>" + this.searchClient + "</b>");
						} */

						return true;
					}

					return false;
				}, this).sort((a, b) => (a.name > b.name) ? 1 : -1);
			} else {
				return this.freeListOnly ? [] : null;
			}
		},

		newClients() {
			return this.allClients.concat(this.enteredClients).filter(client => isNaN(client.id) && !client.isNonClient);
		},

		newTickets() {
			return this.allClients.concat(this.enteredClients).filter(client => client.isNewTicket).map(client => ({
				clientId: client.id,
				phone: client.phone,
				ticketPunches: client.ticketPunches
			}));
		},

		editedClients() {
			return this.allClients.concat(this.enteredClients).filter(client => client.isEdited).map(client => ({
				clientId: client.realId ? client.realId : client.id,
				phone: client.phone,
				name: client.name
			}));
		},

		filteredEnteredClients() {
			let enteredClients = this.enteredClients;

			if (this.entryFilter)
				enteredClients = enteredClients.filter(client => client.paymentMethod === this.entryFilter || client.method === this.entryFilter)

			if (this.searchEntered)
				enteredClients = enteredClients.filter(client => client.name.includes(this.searchEntered));

			return enteredClients;
		},

		coupledClients() {
			return this.enteredClients.filter(client => client.reason == 'כניסה בזוג');
		}
	},
	methods: {
		// @TEMP
		/* addFreeList(id) {
			// console.log(this.$refs['result_' + id], this.$refs['reason_' + id].options[this.$refs['reason_' + id].selectedIndex].text, this.$refs['reason_text_' + id].value);

			var params = new URLSearchParams();
			params.append('client_id', id);
			params.append('reason', this.$refs['reason_text_' + id].value ? this.$refs['reason_text_' + id].value : this.$refs['reason_' + id].options[this.$refs['reason_' + id].selectedIndex].text);

			window['axios'].post(
				'functions.php?test=1',
				params
			).then(() => {
				this.$refs['result_' + id].parentNode.removeChild(this.$refs['result_' + id]);
			});
		},
		removeFreeList(id) {
			var params = new URLSearchParams();
			params.append('client_id', id);

			window['axios'].post(
				'functions.php?test=1&action=remove',
				params
			).then(() => {
				this.$refs['result_' + id].parentNode.removeChild(this.$refs['result_' + id]);
			});
		}, */
		searchFormat(item) {
			return item.name + ', ' + item.phone + (item.isFree ? ' (' + item.reason + ')' : '');
		},
		addFreeClient() {
			var clientIndex		= this.allClients.findIndex((clientObject) => {
				return clientObject['id'] == this.selectedClient['id'];
			}), selectedClient	= this.allClients[clientIndex],
				params			= new URLSearchParams();

			params.append('clientId', selectedClient['id']);
			params.append('reason', this.freeReason);

			window['axios'].post(
				'functions.php?addNewFreeClient=1',
				params
			).then(() => {
				this.$buefy.toast.open({
					message: 'הלקוח נוסף לרשימת החינמים בהצלחה.',
					type: 'is-success'
				});

				selectedClient['isFree'] = true;
				selectedClient['reason'] = this.freeReason;
				this.$set(this.allClients, clientIndex, selectedClient);

				//
				this.freeReason = '';
				this.searchClient = '';
			});
		},
		removeFreeClient() {
			var params = new URLSearchParams();
			params.append('ids', this.freeClientsToDelete);

			window['axios'].post(
				'functions.php?test=1&action=remove',
				params
			).then(() => {
				this.$buefy.toast.open({
					message: 'הלקוחות המסומנים הוסרו מרשימת החינמים בהצלחה.',
					type: 'is-success'
				});

				this.allClients.forEach((clientObject, index) => {
					if (this.freeClientsToDelete.indexOf(clientObject.id) > -1) {
						delete clientObject['isFree'];
						delete clientObject['reason'];
						this.$set(this.allClients, index, clientObject);
					}
				});

				this.freeClientsToDelete = [];
			});
		},
		setEdit(id, freeReason) {
			this.$set(this.isEdit, id, this.isEdit[id] ? false : freeReason)
		},
		changeFreeReason(clientId) {
			var clientIndex		= this.allClients.findIndex(function(clientObject) {
				return clientObject['id'] == clientId;
			}), selectedClient	= this.allClients[clientIndex],
				params			= new URLSearchParams();

			params.append('clientId', clientId);
			params.append('reason', this.isEdit[clientId]);

			window['axios'].post(
				'functions.php?changeFreeReason=1',
				params
			).then(() => {
				selectedClient['reason'] = this.isEdit[clientId];

				this.$set(this.allClients, clientIndex, selectedClient);
				this.$set(this.isEdit, clientId, false);
			});
		},
		/* ####################################### */

		/** ### */
		getAllClientsFromDB() {
			this.isLoadingClients = true;

			//
			fetch((process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_SERVER_PATH : process.env.BASE_URL) + 'functions.php', {
				cors: 'no-cors'
			}).then(response => response.json())
			.then(json => {
				this.allClients		= json;
				this.dataIsPulled	= true;
				this.isLoadingClients = false;

				//
				setTimeout(() => {
					this.$buefy.toast.open({
						message: 'הנתונים נטענו בהצלחה.',
						type: 'is-success'
					});
				}, 100);

				//
				this.$nextTick(() => {
					localStorage.setItem('lastUpdated', Date.now() / 1000);
					localStorage.setItem('dataIsUpToDate', true);
				});
			});
		},

		updateData() {
			this.$buefy.dialog.confirm({
				message:
					`האם הנכם בטוחים שברצונכם לסגור את הקופה "${this.cashierTitle}" (${this.cashierDate})?
					<br /><br />
					<b>מזומן התחלתי:</b> ₪${this.initialCash}<br />
					<b>סה"כ רכישות:</b> ₪${this.creditCardMoney + this.purchaseCash}<br />
					<b>מזומן שהוצא מהקופה:</b> ₪${this.withdrawnCash}<br />
					<b>מזומן בקופה:</b> ₪${this.cashierCash}<br />
					<b>סה"כ כניסות:</b> ${this.enteredClients.length}<br />
					<b>לקוחות חדשים:</b> ${this.newClients.length}<br />
					<b>כרטיסיות חדשות:</b> ${this.newTickets.length}`,
				type: 'is-warning',
				hasIcon: true,
				onConfirm: () => {
					this.isLoadingClients = true;

					var params = new URLSearchParams();
					params.append('allEntrances', JSON.stringify(this.enteredClients));
					params.append('allClients', JSON.stringify(this.allClients));
					params.append('newClients', JSON.stringify(this.newClients));
					params.append('editedClients', JSON.stringify(this.editedClients));
					params.append('newTickets', JSON.stringify(this.newTickets));
					params.append('cashWithdrawals', JSON.stringify(this.cashWithdrawals));
					params.append('initialCash', JSON.stringify(this.initialCash));
					params.append('cashierTitle', JSON.stringify(this.cashierTitle));

					window['axios'].post(
						'/functions.php?update=1' + (this.isHistory ? '&log=' + this.isHistory['id'] : ''),
						params
					).then(() => {
						this.isLoadingClients = false;
						this.dataIsPulled	  = false;
						this.isHistory		  = null;

						//
						setTimeout(() => {
							this.$buefy.toast.open({
								message: 'תודה, הקופה נסגרה בהצלחה.',
								type: 'is-success'
							});
						}, 100);

						//
						this.clearCashier();
					});
				}
			});
		},

		loadLog() {
			fetch((process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_SERVER_PATH : process.env.BASE_URL) + 'functions.php?getLog=1')
			.then(response => response.json())
			.then(json => {
				this.$buefy.modal.open({
					parent: this,
					hasModalCard: true,
					component: LoadLogModal,
					props: {
						logsList: json
					},
					events: {
						selected: logId => {
							if (!logId) return;

							let selectedLog = json.find(log => log.id == logId);

							for (let key in selectedLog) {
								if (key != 'newClients') this[key == 'allEntrances' ? 'enteredClients' : key] = selectedLog[key];
							}

							//
							let allClients = this.enteredClients.concat(this.allClients);

							selectedLog['newClients'].forEach(clientObject =>
								Object.assign(allClients.find(client => client.id == clientObject.id), {realId: clientObject['realId']})
							);

							//
							this.dataIsPulled = true;
							this.isHistory	  = selectedLog;

							//
							this.$nextTick(() => {
								localStorage.setItem('lastUpdated', Date.now() / 1000);
								localStorage.setItem('dataIsUpToDate', true);
								localStorage.setItem('isHistory', JSON.stringify(selectedLog));
								localStorage.setItem('initialCash', this.initialCash);
								localStorage.setItem('cashierTitle', this.cashierTitle);
							});
						}
					}
				});
			});
		},

		clearCashier(withConfirm) {
			const clearData = () => {
				this.allClients = [];
				this.enteredClients = [];
				this.cashWithdrawals = [];
				this.initialCash = 0;
				this.cashierTitle = "זה בצ'אטה ניישן!";
				this.isHistory = null;
				this.dataIsPulled = false;

				this.$nextTick(() => localStorage.clear());
			}

			if (withConfirm) {
				this.$buefy.dialog.confirm({
					message: 'האם הנכם בטוחים?',
					type: 'is-danger',
					hasIcon: true,
					onConfirm: clearData
				});
			} else {
				clearData();
			}
		},

		/* updateNewClients() {
			let newClients = this.newClients;

			newClients.forEach(newClient => {
				let clientObject = this.enteredClients.find(client => client['phone'] == newClient['phone']);

				//
				if (!clientObject) clientObject = this.allClients.find(client => client['phone'] == newClient['phone']);

				//
				if (clientObject) newClient.ticketPunches = clientObject.ticketPunches;
			});

			window['axios'].post('/functions.php?update=1&newClients=1', newClients);
		}, */

		/** ## Passive Functions ## **/
		showPaymentMethodIcon(paymentMethod, cashPayed/* , dataBadge */) {
			const paymentMethodIcons = {
				'cash': {
					'colorClass': "has-text-success",
					'iconClass': "fas fa-shekel-sign",
					'title': "תשלום במזומן",
					'hasBadge': "success",
				},
				'credit-card': {
					'colorClass': "has-text-primary",
					'iconClass': "far fa-credit-card",
					'title': "תשלום באשראי",
					'hasBadge': "primary",
					'dataBadge': cashPayed
				},
				'special-price': {
					'colorClass': "has-text-danger",
					'iconClass': "fas fa-percent",
					'title': "מחיר מיוחד",
					'hasBadge': "danger",

				},
				'ticket': {
					'colorClass': "has-text-warning",
					'iconClass': "fas fa-ticket-alt",
					'title': "ניקוב בכרטיסייה",
					'hasBadge': "warning",
				},
				'double-punch': {
					'colorClass': "has-text-warning",
					'iconClass': "fa fa-check-double",
					'title': "ניקוב בכרטיסייה של לקוח אחר",
				},
				'free': {
					'colorClass': "has-text-link",
					'iconClass': "fas fa-door-open",
					'title': "כניסה חינם"
				},
				'points': {
					'colorClass': "has-text-grey-lighter",
					'iconClass': "fas fa-award",
					'title': "כניסה עם נקודות"
				}
			};

			//
			var PMIcon = paymentMethodIcons[paymentMethod];

			// generate icon
			return	'<span class="icon ' + PMIcon['colorClass'] + (PMIcon['hasBadge'] ? ' has-badge-rounded has-badge-outlined has-badge-small has-badge-left has-badge-' + PMIcon['hasBadge'] : '') + '"' + (PMIcon['dataBadge'] || cashPayed ? 'data-badge="' + (PMIcon['dataBadge'] || cashPayed) : '') + '" title="' + PMIcon['title'] + '">' +
						'<i class="' + PMIcon['iconClass'] + ' fa-lg"></i>' +
					'</span>';
		},

		validateSearchInput(event) {
			let checkNumbers = /\d+/ig,
				checkLetters = /[a-zA-Z\u0590-\u05FF']+/ig;

			//
			if (event.key == 'Escape') {
				this.searchClient = '';
			}

			//
			if (this.searchClient && this.searchClient.length != event.target.selectionEnd - event.target.selectionStart &&
				event.key != 'Delete' && event.key != 'Backspace' && !event.ctrlKey && !event.shiftKey
			) {
				if (checkNumbers.test(this.searchClient) && checkLetters.test(event.key) ||
					checkLetters.test(this.searchClient) && checkNumbers.test(event.key)
				) {
					event.preventDefault();
				}
			}
		},

		getIconForSearchInput() {
			if (this.searchClient.length) {
				return /\d+/ig.test(this.searchClient) ? 'phone' : 'spell-check';
			}
		},

		/** ## Search Input Actions ## **/
		clearSearch() {
			this.searchClient = '';
			this.$refs.searchClient.focus();
		},

		addClient() {
			let inputType = isNaN(this.searchClient) ? 'name' : 'phone',
				messageText = "נא הזינו " + (inputType == 'name' ? "מספר טלפון עבור " : "שם לקוח עבור מספר טלפון ") + this.searchClient + ":";

			this.$buefy.dialog.prompt({
				message: messageText,
				confirmText: 'אישור',
				inputAttrs: {
					onFocus: "this.select()"
				},
				closeOnConfirm: false,
				onConfirm: (newClient, {close}) => {
					if (newClient) {
						let newClientObject;

						if (inputType == 'name') {
							if (this.allClients.concat(this.enteredClients).some(client => client.phone == newClient)) {
								return this.$buefy.dialog.alert({
									message: 'כבר קיים לקוח עם מספר הטלפון שהוזן.',
									title: 'לא ניתן להשלים את הפעולה',
									type: 'is-danger',
									hasIcon: true
								});
							}

							newClientObject = {'name': this.searchClient, 'phone': newClient};
						} else {
							newClientObject = {'name': newClient, 'phone': this.searchClient};
						}

						newClientObject['id'] = 'temp_' + (this.allClients.length + this.enteredClients.length); //

						this.allClients.push(newClientObject);

						// @TEMP
						if (this.freeListOnly) {
							var params = new URLSearchParams();
							params.append('name', newClientObject['name']);
							params.append('phone', newClientObject['phone']);

							window['axios'].post(
								'functions.php?addNewClient=1',
								params
							).then((response) => {
								this.$buefy.toast.open({
									message: 'הלקוח נוסף בהצלחה למערכת.',
									type: 'is-success'
								});

								this.$refs.autocomplete.setSelected(newClientObject);

								//
								newClientObject['id'] = parseInt(response.data);
								// Vue.set(this.allClients, this.allClients.length - 1, newClientObject);
							});
						}

						//
						close();
					}
				}
			});
		},

		/** ## Search Results Actions ## **/
		removeClient(clientIndex) {
			let removedClient = this.enteredClients[clientIndex];

			if (this.enteredClients.some(client => client['punchedTicketClientId'] == removedClient['id'])) {
				return this.$buefy.dialog.alert({
					message: 'לא ניתן להסיר את הכניסה של ' + removedClient['name'] + ' מכיוון שיש לקוחות שנכנסו באמצעות הכרטיסייה שלו/ה.',
					title: 'שימו לב',
					type: 'is-danger',
					hasIcon: true
				});
			}

			this.$buefy.dialog.confirm({
				message:
					"האם הנכם בטוחים שברצונכם למחוק את הכניסה של " + removedClient['name'] + (removedClient.isNonClient ? "" : ", מספר טלפון: " + removedClient['phone']) + "?" +
					(removedClient['isNewTicket'] ?
					`<br /><br />
					<label class="b-checkbox checkbox is-small">
						<input type="checkbox" id="cancel-new-ticket">
						<span class="check is-warning"></span>
						<span class="control-label">סמן אם ברצונך לבטל בנוסף גם את רכישת הכרטיסייה.</span>
					</label>` : '') +
					"<br /><br /><b>שימו לב:</b> לא ניתן לבטל פעולה זו לאחר אישורה.",
				type: 'is-warning',
				hasIcon: true,
				onConfirm: () => {
					//
					this.enteredClients.splice(clientIndex, 1);

					//
					if (removedClient['paymentMethod'] == 'ticket') {
						if (removedClient['isNewTicket'] && document.querySelector('#cancel-new-ticket').checked) {
							delete removedClient['ticketPunches'];
							delete removedClient['isNewTicket'];
						} else {
							removedClient['ticketPunches']++;
						}
					} else if (removedClient['paymentMethod'] == 'double-punch') {
						//
						var punchedTicketclientIndex = this.enteredClients.findIndex(function(clientObject) {
							return clientObject['id'] == removedClient['punchedTicketClientId'];
						}), punchedTicketClient		 = this.enteredClients[punchedTicketclientIndex];

						punchedTicketClient['ticketPunches']++;

						delete removedClient['punchedTicketClientId'];
					}

					//
					if (!removedClient.isNonClient) {
						delete removedClient['paymentMethod'];
						delete removedClient['timeEntered'];
						delete removedClient['cashPayed'];
						delete removedClient['comment'];
						delete removedClient['moneyPayed'];
						delete removedClient['method'];

						//
						if (!removedClient['isFree'] && !removedClient['isDiscount']) delete removedClient['reason'];

						/* this.allClients.push(removedClient); */
						delete this.allClients[removedClient['clientIndex']].isEntered;
					}

					//
					if (!clientIndex && !this.searchClient && (
						removedClient['name'].indexOf(this.lastSearched) > -1 || removedClient['phone'].indexOf(this.lastSearched) > -1
					)) {
						this.searchClient = this.lastSearched;
					}

					//
					this.$refs.searchClient.focus();

					// TODO: save records of removed clients
				},
				onCancel: () => {
					//
					this.$refs.searchClient.focus();
				}
			});
		},

		addComment(clientIndex) {
			var selectedClient	= this.enteredClients[clientIndex];

			this.$buefy.dialog.prompt({
				message: "הזינו הערה עבור הכניסה של " + selectedClient['name'] + ":",
				confirmText: 'אישור',
				inputAttrs: {
					required: false,
					value: selectedClient['comment'],
					onFocus: "this.select()"
				},
				onConfirm: (comment) => {
					selectedClient['comment'] = comment;

					//
					this.$set(this.enteredClients, clientIndex, selectedClient);
				}
			});
		},

		saveClient() {
			let editedClient = this.enteredClients.concat(this.allClients).find(client => client.id == this.isEditingClient);

			//
			if (this.enteredClients.concat(this.allClients).some(client => client['phone'] == editedClient['phone'] && client != editedClient)) {
				let undoPhone;

				while (undoPhone !== editedClient['phone']) {
					undoPhone = editedClient['phone'];
					document.execCommand('undo', false, null);
				}

				setTimeout(() => {
					this.$buefy.dialog.alert({
						message: 'כבר קיים לקוח עם מספר הטלפון שהוזן.',
						title: 'לא ניתן להשלים את הפעולה',
						type: 'is-danger',
						hasIcon: true
					});
				});

				return;
			}

			// add client to edited clients array, only if it's not a new client
			if (!isNaN(editedClient.id) || editedClient['realId']) this.$set(editedClient, 'isEdited', true);

			// exit editing mode
			this.isEditingClient = null;
		},

		/** ## Cash Withdrawal Actions ## **/
		withdrawCash() {
			var cashToWithdraw = parseInt(prompt("כמה כסף ברצונכם להוציא מהקופה?"));

			if (!isNaN(cashToWithdraw) && cashToWithdraw <= this.cashierCash) {
				let reasonToWithdraw, cancelAction;

				do {
					if ((reasonToWithdraw = prompt("נא ציינו את סיבת ההוצאה:"))) {
						this.cashWithdrawals.unshift({
							'cashWithdrawn': cashToWithdraw,
							'withdrawReason': reasonToWithdraw,
							'withdrawTime': this.$root.timeEntered()
						});
					} else if ((cancelAction = confirm("האם לבטל את הוצאת הכסף?"))) {
						return;
					}
				} while (!reasonToWithdraw && !cancelAction)
			} else if (cashToWithdraw > this.cashierCash) {
				this.$buefy.dialog.alert("אין מספיק כסף בקופה כדי לבצע את הפעולה המבוקשת.");
			}
		},

		removeWithdraw(withdrawIndex) {
			this.$buefy.dialog.confirm({
				message:
					"האם הנכם בטוחים שברצונכם לבטל הוצאת כסף זו?" +
					"<br /><br /><b>שימו לב:</b> לא ניתן לבטל פעולה זו לאחר אישורה.",
				type: 'is-danger',
				hasIcon: true,
				onConfirm: () => {
					this.cashWithdrawals.splice(withdrawIndex, 1);

					//
					if (!this.cashWithdrawals.length) this.currentTab = 0;
				}
			});
		},

		/** ## Misc. Cashier Actions ## */
		updateInitialCash(isFirstLoad) {
			this.$buefy.dialog.prompt({
				message: "כמה כסף יש בקופה?",
				confirmText: 'אישור',
				inputAttrs: {
					type: 'number',
					min: 0,
					value: this.initialCash.toString(),
					onFocus: "this.select()"
				},
				onConfirm: (value) => {
					this.initialCash = parseInt(value);

					//
					localStorage.setItem('initialCash', this.initialCash);

					//
					if (isFirstLoad) this.getAllClientsFromDB();
				}
			});
		},

		updateCashierName(isFirstLoad) {
			this.$buefy.dialog.prompt({
				message: "בחרו שם לקופה:",
				confirmText: 'אישור',
				inputAttrs: {
					required: false,
					value: this.cashierTitle,
					onFocus: "this.select()"
				},
				onConfirm: (value) => {
					if (value) {
						this.cashierTitle = value;
						this.tabsList[0]  = this.cashierTitle;

						//
						localStorage.setItem('cashierTitle', this.cashierTitle);
					}

					//
					if (isFirstLoad) this.updateInitialCash(true);
				}
			});
		},

		openFiltersModal() {
			if (this.entryFilter) {
				this.entryFilter = null;
				return;
			}

			this.$buefy.modal.open({
				parent: this,
				component: FiltersModal,
				hasModalCard: true,
				events: {
					selected: filter => {
						this.entryFilter = filter;
					}
				}
			});
		},

		getTotalPerMethod(method) {
			let methodPayed = this.enteredClients.filter(client => client.method === method).map(client => client.moneyPayed);

			return methodPayed.length ? methodPayed.reduce((a, b) => a + b) : 0;
		}
	},
	created() {
		//
		this.$buefy.config.setOptions({
			defaultIconPack: 'fa',
			defaultDialogConfirmText: 'אישור',
			defaultDialogCancelText: 'ביטול',
			defaultModalScroll: 'keep'
		});
	},
	beforeMount() {
		window.addEventListener("beforeunload", event => {
			event.preventDefault();
			event.returnValue = "123"; // Chrome requires returnValue to be set.

			return "sdfsdf"
		});
	},
	mounted() {
		//
		if (typeof localStorage['dataIsUpToDate'] != 'undefined' && (
			!localStorage['lastUpdated'] /* || localStorage['lastUpdated'] < lastUpdated */
		)) {
			localStorage.clear();
		}

		//
		if (!this.dataIsPulled) {
			setTimeout(() => {
				//
				this.cashierTitle = this.$refs.cashierTitle.innerText;

				//
				this.$buefy.dialog.confirm({
					message: 'האם לפתוח קופה חדשה?',
					onConfirm: () => this.updateCashierName(true)
				});
			});
		}
	},
	watch: {
		allClients: {
			deep: true,
			handler(allClients) {
				localStorage.removeItem('dataIsUpToDate');
				localStorage.setItem('allClients', JSON.stringify(allClients));
			}
		},
		enteredClients: {
			deep: true,
			handler(enteredClients) {
				localStorage.removeItem('dataIsUpToDate');
				localStorage.setItem('enteredClients', JSON.stringify(enteredClients));
			}
		},
		cashWithdrawals(cashWithdrawals) {
			localStorage.removeItem('dataIsUpToDate');
			localStorage.setItem('cashWithdrawals', JSON.stringify(cashWithdrawals));
		}
	}
}
</script>

<style>
@import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
@import 'assets/main.css';

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
